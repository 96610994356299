import CryptoComponent from "../CryptoComponent";
import CryptoService from "../../../service/crypto/CryptoService";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {Form} from "react-bootstrap";
import React, {ChangeEvent, useEffect, useState} from "react";
import {Slider} from "@mui/material";


const CryptoPriceSimulatorComponent = () => {
    const volume = CryptoService.getVolume()

    const [currentPrice, setCurrentPrice] = useState<number>(3500)
    const [shortValue, setShortValue] = useState<number>(0.5);
    const [cryptoValue, setCryptoValue] = useState<number>(3500);
    const [usdValue, setUsdValue] = useState<number>(3500);
    const [profit, setProfit] = useState<number>(0);
    const [poolProfit, setPoolProfit] = useState<number>(0);
    const [shortProfit, setShortProfit] = useState<number>(0);
    const [futurePrice, setFuturePrice] = useState<number>(3500);
    const [poolValue, setPoolValue] = React.useState<number[]>([Math.round(currentPrice * 0.9), Math.round(currentPrice * 1.1)]);


    useEffect(() => {
        return () => {
            null
        };
    }, [futurePrice]);


    const handleCurrentPriceChange = (e: ChangeEvent<HTMLInputElement>) => {
        const value = !Number.isNaN(e.target.valueAsNumber) ? e.target.valueAsNumber : 0;
        setCurrentPrice(value);
    }

    const handleCurrentPriceSliderChange = (_: Event, value: number | number[]) => {
        const newVal = Array.isArray(value) ? value[0] : value;
        setCurrentPrice(newVal);
    }

    const handleFuturePriceChange = (e: ChangeEvent<HTMLInputElement>) => {
        const value = !Number.isNaN(e.target.valueAsNumber) ? e.target.valueAsNumber : 0;
        setFuturePrice(value);
        updateShortProfit()
        updatePoolProfit()
    }

    const handleFuturePriceSliderChange = (_: Event, value: number | number[]) => {
        const newVal = Array.isArray(value) ? value[0] : value;
        setFuturePrice(newVal);
        updateShortProfit()
        updatePoolProfit()
    }


    const handlePoolChange = (event: Event, newValue: number | number[]) => {
        setPoolValue(newValue as number[]);
    };

    const handleCryptoValueChange = (e: any) => {
        const value = !Number.isNaN(e.target.valueAsNumber) ? e.target.valueAsNumber : cryptoValue;
        setCryptoValue(value);
        setUsdValue((poolValue[1] - currentPrice) / (currentPrice - poolValue[0]) * value)
    }

    const handleUsdValueChange = (e: ChangeEvent<HTMLInputElement>) => {
        const value = !Number.isNaN(e.target.valueAsNumber) ? e.target.valueAsNumber : usdValue;
        setUsdValue(value);
        setCryptoValue((currentPrice - poolValue[0]) / (poolValue[1] - currentPrice) * value)
    }

    const updatePoolValue = (newValue: number[]) => {
        setPoolValue(newValue)
    }

    const handleShortValueChange = (e: ChangeEvent<HTMLInputElement>) => {
        const value = !Number.isNaN(e.target.valueAsNumber) ? e.target.valueAsNumber : 0;
        setShortValue(value);
    }


    const updateShortProfit = () => {
        setShortProfit(shortValue * (currentPrice - futurePrice))
    }

    const updatePoolProfit = () => {
        const priceDiff = futurePrice - currentPrice
        console.log("Price difference = " + priceDiff)
        let profit = 0

        if (priceDiff >= 0) {
            profit = (priceDiff / 2) * cryptoValue/currentPrice;
        } else {
            profit = priceDiff * cryptoValue/currentPrice - (priceDiff / 2) * usdValue/currentPrice;
        }
        console.log("Pool profit = " + poolProfit)
        setPoolProfit(profit)
    }

    const updateProfit = () => {
        const profit = 0;
        setProfit(profit)
    }


    return (

        <>
            <Row>
                <Col md={6} lg={2}>
                    <Form.Group className="mb-3 d-inline-block" controlId="currentPrice">
                        <Form.Label>Current Price</Form.Label>
                        <Form.Control
                            aria-label="$"
                            type="number"
                            min="1"
                            name="current price"
                            value={currentPrice}
                            onChange={handleCurrentPriceChange}
                        />
                    </Form.Group>
                </Col>
                <Col md={6} lg={2}>
                    <Form.Group className="mb-3 d-inline-block" controlId="currentPrice">
                        <Form.Label>Short value (eth)</Form.Label>
                        <Form.Control
                            aria-label="$"
                            type="number"
                            min="1"
                            name="short value"
                            value={shortValue}
                            onChange={handleShortValueChange}
                        />
                    </Form.Group>
                </Col>
            </Row>

            <Row>
                <Col sm={12}>
                    {/*<Form.Label>Future Price: {futurePrice}</Form.Label>*/}
                    <Slider
                        getAriaLabel={() => 'Current price range'}
                        track={false}
                        value={currentPrice}
                        min={2000}
                        max={5000}
                        defaultValue={currentPrice}
                        onChange={handleCurrentPriceSliderChange}
                        valueLabelDisplay="auto"

                    />
                </Col>
            </Row>


            <Row className="my-5">
                <Col>
                    Pool
                    <Slider
                        getAriaLabel={() => 'Pool range'}
                        valueLabelDisplay="on"
                        value={poolValue}
                        onChange={handlePoolChange}
                        min={2000}
                        max={5000}
                    />
                </Col>
            </Row>


            <Row>
                <Col md={6} lg={2}>
                    <Form.Group className="mb-3 d-inline-block" controlId="usdValue">
                        <Form.Label>Crypto (USD value)</Form.Label>
                        <Form.Control
                            aria-label="$"
                            type="number"
                            min="1"
                            name="usd value"
                            value={cryptoValue}
                            onChange={handleCryptoValueChange}
                        />
                    </Form.Group>
                </Col>
                <Col md={6} lg={2}>
                    <Form.Group className="mb-3 d-inline-block" controlId="cryptoValue">
                        <Form.Label>USD</Form.Label>
                        <Form.Control
                            aria-label="$"
                            type="number"
                            min="1"
                            name="crypto value"
                            value={usdValue}
                            onChange={handleUsdValueChange}
                        />
                    </Form.Group>
                </Col>
            </Row>


            <Row className="mt-2">
                <Col md={6} lg={2}>
                    <Form.Group className="mb-3 d-inline-block" controlId="futurePrice">
                        <Form.Label>Future Price</Form.Label>
                        <Form.Control
                            aria-label="$"
                            type="number"
                            min="1"
                            step="1"
                            name="futurePrice"
                            placeholder="1"
                            value={futurePrice}
                            onChange={handleFuturePriceChange}
                        />
                    </Form.Group>
                </Col>
            </Row>

            <Row className="my-3">
                <Col sm={12}>
                    {/*<Form.Label>Future Price: {futurePrice}</Form.Label>*/}
                    <Slider
                        getAriaLabel={() => 'Future price range'}
                        value={futurePrice}
                        min={2000}
                        max={5000}
                        defaultValue={futurePrice}
                        onChange={handleFuturePriceSliderChange}
                        valueLabelDisplay="auto"

                    />
                </Col>

            </Row>

            <Row>
                <Col sm={12} className="py-2">
                    Pool Profit: ${poolProfit}
                </Col>
                <Col sm={12} className="py-2">
                    Short Profit: ${shortProfit}
                </Col>
                <Col sm={12} className="py-2 h6 fw-semibold">
                    Profit: ${poolProfit + shortProfit}
                </Col>
            </Row>

        </>

    )

}


export default CryptoPriceSimulatorComponent;