import {cacheExchange, createClient, fetchExchange, Provider, useQuery} from 'urql';
import {IGraphData} from "../../model/crypto/Graph";
import {useEffect, useState} from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {Slider} from "@mui/material";
import {Form} from "react-bootstrap";
import CryptoService from "../../service/crypto/CryptoService";
import StockService from "../../service/StockService";
import CryptoPriceSimulatorComponent from "./simulator/CryptoPriceSimulatorComponent";


const QUERY = `{
  poolDayDatas(orderDirection: desc, where: {date_gt: 10}) {
    date
    feesUSD
    high
    low
    volumeUSD
  }
  bundles(first: 5) {
    id
    ethPriceUSD
  }
}`;


const CryptoComponent = () => {
    /*const [result] = useQuery<IGraphData>({ query: QUERY })*/

    const volume = CryptoService.getVolume()
    const data = CryptoService.getData(` {
  pools(where: {liquidity_gt: "0"}) {
    token1 {
      name
      symbol
    }
    token0 {
      name
      symbol
    }
    liquidity
    volumeUSD
  }
}`)


    function valuetext(value: number) {
        return `${value}°C`;
    }

    const minDistance = 1;


    const [value, setValue] = useState<number[]>([3600, 3800]);


    const handleChange = (
        event: Event,
        newValue: number | number[],
        activeThumb: number,
    ) => {
        if (!Array.isArray(newValue)) {
            return;
        }

        if (newValue[1] - newValue[0] < minDistance) {
            if (activeThumb === 0) {
                const clamped = Math.min(newValue[0], 100 - minDistance);
                setValue([clamped, clamped + minDistance]);
            } else {
                const clamped = Math.max(newValue[1], minDistance);
                setValue([clamped - minDistance, clamped]);
            }
        } else {
            setValue(newValue as number[]);
        }
    };


    return (
        <>
            <CryptoPriceSimulatorComponent/>


        </>
    )

}


export default CryptoComponent;