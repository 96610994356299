import React, {useEffect, useState} from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {CardBody} from "react-bootstrap";
import CardHeader from "react-bootstrap/CardHeader";
import Card from "react-bootstrap/Card";
import Layout from "../../components/layout/Layout";
import abi from './abi.json';
import { MetaMaskInpageProvider } from "@metamask/providers";

declare global {
    interface Window{
        ethereum?:MetaMaskInpageProvider
    }
}






export const CryptoPositionPage = () => {
    const contractAddress = "0xC36442b4a4522E871399CD717aBDD847Ab11FE88"
    const [currentAd, setCurrentAd] = useState("Hello World!");
    const [currentBid, setCurrentBid] = useState(0);
    const [advertiser, setAdvertiser] = useState("0x0");
    const [newAd, setNewAd] = useState("");
    const [bidAmount, setBidAmount] = useState("");
    const [provider, setProvider] = useState(null);
    const [status, setStatus] = useState("");
    const [isMetaMaskInstalled, setIsMetaMaskInstalled] = useState(false);
    const [walletAddress, setWalletAddress] = useState("");
    const [address, setAddress] = useState<string | null>(null);

    const connectWallet = async () => {
        if (!window?.ethereum) {
            alert("No wallet found. Please install MetaMask.");
            return;
        }

        try {
            const accounts: any = await window.ethereum.request<string[]>({
                method: "eth_requestAccounts",
            });

            if (accounts.length === 0) {
                alert("No authorized account found");
            }

            setAddress(accounts[0]);
        } catch (error) {
            console.error("error", error);
        }
    };

    useEffect(() => {
        if (
            typeof window.ethereum !== "undefined" &&
            typeof window.ethereum.selectedAddress == "string"
        ) {
            setAddress(window.ethereum.selectedAddress);
        }
    }, []);

    useEffect(() => {
        if (!window?.ethereum) {
            return;
        }

        const listener: any = ([selectedAddress]: string[]) => {
            setAddress(selectedAddress);
        };

        window.ethereum.on("accountsChanged", listener);

        return () => {
            window.ethereum!.removeListener("accountsChanged", listener);
        };
    }, []);


    const content =
            <>

                <button onClick={connectWallet}>
                    {address ? address : `Connect Wallet`}
                </button>

            </>


        return (
            <>
                <Layout>
                    <Row className="g-3">
                        <Col sm={12}>

                            <Card>
                                <CardHeader>
                                    <h6>My crypto position</h6>
                                </CardHeader>

                                <CardBody className="">

                                    {content}

                                </CardBody>
                            </Card>

                        </Col>
                    </Row>
                </Layout>
            </>
        );
    }
;
